import classNames from 'classnames';
import styles from './style.module.scss';
import ChatBalloon from 'components/chatBalloon/ChatBalloon';

const Title = (props) => {
  return (
    <>
      <div className={classNames(styles.title)}>
        <img src={props.image} alt={props.name} />
      </div>

      <div className={classNames(styles.balloon)}>
        <ChatBalloon text={props.text} />
      </div>
    </>
  );
};

export default Title;
