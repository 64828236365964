import MemberAll from 'assets/member_all.png';
import MemberHari from 'assets/member_hari.png';
import MemberRisa from 'assets/member_risa.png';
import MemberTaro from 'assets/member_taro.png';

const member = [
  {
    name: '',
    role: '',
    color: '#714A44',
    img: MemberAll,
    link: '/about',
    desc: `HariRO.Tokyoはエンタメコンテンツのプロデュース・制作および、制作チーム編成・ディレクションを行っている会社です。\n
    私たちは、企画立案や制作過程に心地よさや笑顔があることで、クリエイティビティと共に純度の高いエンタメが生まれ、たくさんの人々を惹きつけるものが実現できると信じています。\n
    人々の心が揺さぶられる結果を実現するために、制作物にも現場にも意識を向けながら”感情曲線をデザイン”していきます。`,
  },
  {
    name: '播口 智也',
    role: '代表取締役/CEO',
    color: '#E22A25',
    img: MemberHari,
    link: '/about/hariguchi_tomoya',
    desc: `ゲームや音楽など、多くの業界から得たクリエイティブポイントを活かし繋げるプロデューサー。
    時代や世界の流れを深く考察してこれからの世の中に必要なものを見出しながら、人々の価値観や個性を大切にしたマネジメントで「高みを目指す現場づくり」を実現する。`,
  },
  {
    name: 'Risa',
    role: 'Chief Designer',
    color: '#FF90B2',
    img: MemberRisa,
    link: '/about/risa',
    desc: `相手の気持ちや印象に寄り添うことを第一とする、グラフィック&UIUXデザイナー。
    マネジメントからドキュメントまであらゆるアウトプットに対して気を配りながら、細かな状態の変化を見逃さない。
    エンタメ好きならではのユーモアやひらめきと、明確なコンセプトに基づく設計目線のバランスを大切にしている。`,
  },
  {
    name: 'おかだ たろー',
    role: 'Chief Engineer',
    color: '#544DAC',
    img: MemberTaro,
    link: '/about/okada_taro',
    desc: `ひたむきな努力と探究心で必ず実を結ばせる勤勉家のエンジニア。
    良いものを作りたいという熱量と冷静なスケジュール管理能力を兼ね備えつつ、仕様の読み取り力や親切さを意識したコミュニケーションで現場を円滑に進める。
    自分にしか出せない”ロール”を果たすオールラウンダー。`,
  },
];

export default member;
