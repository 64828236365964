/**
 * フェードアニメーション
 */
export const createFadingAnime = (speed) => {
  return {
    entering: { transition: `all ${speed}s`, opacity: 0 },
    entered: { transition: `all ${speed}s`, opacity: 1 },
    exiting: { transition: `all ${speed}s`, opacity: 0 },
    exited: { transition: `all ${speed}s`, opacity: 0 },
    unmounted: { transition: '', opacity: 0 },
  };
};

/**
 * menuアニメーション
 */
export const createMenuAnime = (speed) => {
  return {
    entering: {
      transition: `all ${speed}s`,
      clipPath: 'circle(0px at top left)',
      opacity: 1,
    },
    entered: {
      transition: `all ${speed}s`,
      clipPath: 'circle(100% at center)',
      opacity: 1,
    },
  };
};

/**
 * menu drop downアニメーション
 */
export const createFadingDownAnime = (speed) => {
  return {
    entering: {
      transition: `all ${speed}s`,
      transform: `translateY(-50px)`,
      opacity: 0,
    },
    entered: {
      transition: `all ${speed}s`,
      transform: 'translateY(0px)',
      opacity: 1,
    },
    exiting: {
      transition: `all ${speed}s`,
      transform: `translateY(-50px)`,
      opacity: 0,
    },
    exited: {
      transition: `all ${speed}s`,
      transform: `translateY(-50px)`,
      opacity: 0,
    },
    unmounted: { transition: '', transform: '', opacity: 0 },
  };
};
