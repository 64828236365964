import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import NotFoundImage from 'assets/404.png';
import Title from 'components/title/Title';
import TitleImage from 'assets/title_404.png';
import PageTransitionWrapper from 'styles/PageTransitionWrapper';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 Not Found | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="Notfound"
            image={TitleImage}
            text="アドレスが間違っているか、存在しないページのようです。"
          />

          <PageTransitionWrapper>
            <div className={classNames(styles.notFoundArea)}>
              <Link to="/">TOPへ</Link>

              <img src={NotFoundImage} alt="Not Found" />
            </div>
          </PageTransitionWrapper>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
