const headerItems = [
  {
    label: 'Top',
    link: '/',
  },
  {
    label: 'About Us',
    link: '/about',
  },
  {
    label: 'News',
    link: '/news',
  },
  {
    label: 'Partners',
    link: '/partners',
  },
  {
    label: 'Company profile',
    link: '/company',
  },
  {
    label: 'Contact Us',
    link: '/contact',
  },
];

export default headerItems;
