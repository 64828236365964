import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import classNames from 'classnames';
import styles from './style.module.scss';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Title from 'components/title/Title';
import TitleImage from 'assets/title_contact.png';
import PageTransitionWrapper from 'styles/PageTransitionWrapper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const validations = {
  require: '項目が未入力です',
  maxLength: {
    value: 50,
    message: '50文字以内で入力してください',
  },
  email: {
    value:
      /^([\w!#$%&'*+\-/=?^`{|}~]+(\.[\w!#$%&'*+\-/=?^`{|}~]+)*|"([\w!#$%&'*+\-/=?^`{|}~. ()<>[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
    message: '形式が異なります',
  },
};

const ContactUsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: 'onSubmit' });
  const [isSending, setIsSending] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    setIsSending(true);
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    data.token = (await executeRecaptcha('contact')) || '';

    axios
      .post(process.env.REACT_APP_AWS_FORM_API_URL, data)
      .then((res) => {
        console.log(res);
        setIsSending(false);
        setIsSend(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSending(false);
        setIsError(true);
      });
  };

  return (
    <>
      <Helmet>
        <title>お問い合わせ | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="Contact"
            image={TitleImage}
            text={
              <>
                お気軽にお問い合わせください。すべて
                <span style={{ color: '#E91A58' }}>入力必須</span>
                欄となります。
              </>
            }
          />

          <PageTransitionWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ul>
                <li>
                  <label>お名前</label>
                  <input
                    type="text"
                    placeholder="例）東京　ハリ男"
                    {...register('name', {
                      required: validations.require,
                      maxLength: validations.maxLength,
                    })}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </li>

                <li>
                  <label>御社名</label>
                  <input
                    type="text"
                    placeholder="例）株式会社○○○"
                    {...register('company', {
                      required: validations.require,
                      maxLength: validations.maxLength,
                    })}
                  />
                  {errors.company && <span>{errors.company.message}</span>}
                </li>

                <li>
                  <label>メールアドレス</label>
                  <input
                    type="text"
                    placeholder="例）○○○@mail.com"
                    {...register('email', {
                      required: validations.require,
                      pattern: validations.email,
                    })}
                  />
                  {errors.email && <span>{errors.email.message}</span>}
                </li>

                <li>
                  <label>件名</label>
                  <input
                    type="text"
                    placeholder="例）○○○について"
                    {...register('subject', { required: validations.require })}
                  />
                  {errors.subject && <span>{errors.subject.message}</span>}
                </li>

                <li>
                  <label>お問い合わせ内容</label>
                  <textarea
                    rows={8}
                    {...register('message', { required: validations.require })}
                  />
                  {errors.message && <span>{errors.message.message}</span>}
                </li>
              </ul>

              <input
                type="submit"
                value={isSend ? '送信しました' : isSending ? '送信中' : '送信'}
                disabled={isSend}
              />
              {isError && (
                <p className={classNames(styles.errorText)}>
                  送信エラー 時間をおいてから再度お試しください
                </p>
              )}

              <p className={classNames(styles.recapture)}>
                This site is protected by reCAPTCHA and the Google
                <Link
                  to="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;Privacy Policy&nbsp;
                </Link>
                and
                <Link
                  to="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;Terms of Service&nbsp;
                </Link>
                apply.
              </p>
            </form>
          </PageTransitionWrapper>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
