import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import styles from './style.module.scss';
import PageTransitionWrapper from 'styles/PageTransitionWrapper';
import { getPartner } from 'config/contentful/contentful';
import { Link } from 'react-router-dom';
import Title from 'components/title/Title';
import TitleImage from 'assets/title_partners.png';
import { InView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';

const PartnersPage = () => {
  const nodeRef = useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(0.8);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const _partners = await getPartner();

        setPartners(_partners[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>提携パートナー様 | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="Partners"
            image={TitleImage}
            text="わたし達の提携パートナーです。サムネイルから外部サイトへ移動します。"
          />

          <PageTransitionWrapper>
            <ul>
              {partners.map((v, i) => (
                <InView rootMargin="-50px" triggerOnce key={i}>
                  {({ ref, inView }) => (
                    <CSSTransition
                      in={inView}
                      timeout={{ enter: 0, exit: 100 }}
                      nodeRef={nodeRef}
                    >
                      {(state) => (
                        <Link
                          to={v.link}
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                          ref={ref}
                          style={fade[state]}
                          className={classNames(styles.partnerBox)}
                        >
                          <li>
                            <div className={classNames(styles.imageBox)}>
                              <img src={v.img} alt={v.name} />
                            </div>
                            <p>{v.name}</p>
                          </li>
                        </Link>
                      )}
                    </CSSTransition>
                  )}
                </InView>
              ))}
            </ul>
          </PageTransitionWrapper>
        </div>
      </div>
    </>
  );
};

export default PartnersPage;
