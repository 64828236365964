import { createClient } from 'contentful';

const config = {
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || '',
  space: process.env.REACT_APP_CONTENTFUL_SPACEID || '',
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
};

const client = createClient(config);

/**
 * Base Function
 * @param query
 */
const get = async ({
  content_type,
  limit,
  skip,
  reference,
  order = 'sys.createdAt',
}) => {
  return await client
    .getEntries({
      content_type,
      limit,
      order,
      skip,
      ...reference,
    })
    .catch((error) => {
      console.log(`${error} : ${content_type}`);
    });
};

export const getNews = async (query) => {
  return (
    (
      await get({
        content_type: 'news',
        limit: 4,
        ...query,
        order: '-fields.publishedAt',
      })
    )?.items.map((v) => {
      return {
        slug: v.sys.id,
        title: v.fields.title,
        img: v.fields.thumbnail?.fields.file.url,
        description: v.fields.description,
        published_at: v.fields.publishedAt,
      };
    }) ?? []
  );
};

export const getPartner = async (query) => {
  return (
    (
      await get({
        content_type: 'partnerLists',
        ...query,
      })
    )?.items.map((res) => {
      console.log(res);
      const list = res.fields.list.map((v) => {
        return {
          name: v.fields.name,
          link: v.fields.link,
          img: v.fields.thumbnail?.fields.file.url,
        };
      });
      return list;
    }) ?? []
  );
};
