import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import styles from './style.module.scss';
import Title from 'components/title/Title';
import TitleImage from 'assets/title_profile.png';
import PageTransitionWrapper from 'styles/PageTransitionWrapper';

const CompanyProfilePage = () => {
  return (
    <>
      <Helmet>
        <title>会社情報 | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="Company Profile"
            image={TitleImage}
            text="会社情報です。"
          />

          <PageTransitionWrapper>
            <div className={classNames(styles.infomationArea)}>
              <ul>
                <li>
                  <label>会社名称</label>
                  <p>株式会社HariRO.Tokyo</p>
                </li>

                <li>
                  <label>設立</label>
                  <p>2021年6月1日</p>
                </li>

                <li>
                  <label>登記住所</label>
                  <p>
                    〒160-0022<br></br>
                    東京都 新宿区 新宿三丁目 4番8号
                  </p>
                </li>

                <li>
                  <label>代表</label>
                  <p>播口 智也</p>
                </li>

                <li>
                  <label>事業内容</label>
                  <p>
                    ゲーム・アプリ・Web・映像・音楽等のコンテンツ企画/制作
                    <br></br>
                    商品企画・サービスなどのクリエイティブに関わる全て
                  </p>
                </li>

                <li>
                  <label>主要取引銀行</label>
                  <p>三井住友銀行</p>
                </li>
              </ul>
            </div>
          </PageTransitionWrapper>
        </div>
      </div>
    </>
  );
};

export default CompanyProfilePage;
