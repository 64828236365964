import { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import styles from './style.module.scss';
import Logo from 'assets/logo.png';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';

const HomePage = () => {
  const nodeRef = useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(3);
  const countRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    /*
    if (countRef.current !== null) return;
    let index = 0;
    countRef.current = setInterval(
      () => {
        setIsShowItems((prev) => [...prev, true]);
        index++;

        index > 2 && clearInterval(countRef.current);
      },
      isSession ? 0 : 1000
    );
    */
  }, []);

  return (
    <>
      <Helmet>
        <title>株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <CSSTransition
          in={isMounted}
          timeout={{ enter: 0, exit: 100 }}
          nodeRef={nodeRef}
        >
          {(state) => (
            <div style={fade[state]} className={classNames(styles.content)}>
              <p>感情曲線のデザイン</p>
              <img src={Logo} alt="company logo" />
              <p>ハリロトーキョー</p>

              {/** 
          <CSSTransition
            in={isShowItems[0]}
            timeout={{ enter: 0, exit: 100 }}
            nodeRef={nodeRef}
          >
            {(state) => <p style={fade[state]}>感情曲線のデザイン</p>}
          </CSSTransition>

          <CSSTransition
            in={isShowItems[2]}
            timeout={{ enter: 0, exit: 100 }}
            nodeRef={nodeRef}
          >
            {(state) => (
              <img style={fade[state]} src={Logo} alt="company logo" />
            )}
          </CSSTransition>

          <CSSTransition
            in={isShowItems[1]}
            timeout={{ enter: 0, exit: 100 }}
            nodeRef={nodeRef}
          >
            {(state) => <p style={fade[state]}>ハリロトーキョー</p>}
          </CSSTransition>
          */}
            </div>
          )}
        </CSSTransition>
      </div>
    </>
  );
};

export default HomePage;
