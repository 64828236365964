import { Route, Routes } from 'react-router-dom';
import DefaultLayout from 'layouts/default/DefaultLayout';
import HomePage from 'pages/home/HomePage';
import AboutPage from 'pages/about/AboutPage';
import NewsPage from 'pages/news/NewsPage';
import PartnersPage from 'pages/partners/PartnersPage';
import CompanyProfilePage from 'pages/companyProfile/CompanyProfilePage';
import ContactUsPage from 'pages/contactUs/ContactUs';
import NotFoundPage from 'pages/notFound/NotFoundPage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="" element={<HomePage />} />

        <Route path="about" element={<AboutPage />}>
          <Route path=":params" element={<AboutPage />} />
        </Route>

        <Route path="news">
          <Route path="" element={<NewsPage />} />
        </Route>

        <Route path="partners" element={<PartnersPage />} />
        <Route path="company" element={<CompanyProfilePage />} />
        <Route path="contact" element={<ContactUsPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default App;
