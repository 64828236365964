import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './style.module.scss';
import Title from 'components/title/Title';
import PageTransitionWrapper from 'styles/PageTransitionWrapper';
import ColorTile from 'assets/member_color_tile.png';
import MemberItems from 'config/member';
import TitleImage from 'assets/title_about.png';

const AboutPage = () => {
  const route = useLocation();
  const [data, setData] = useState({});

  useEffect(() => {
    const _data = MemberItems.filter((v) => {
      return route.pathname === v.link;
    });

    setData(..._data);
  }, [route]);

  return (
    <>
      <Helmet>
        <title>{data.name || 'メンバー紹介'} | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="About Us"
            image={TitleImage}
            text="わたし達HariRO.Tokyoについて。もっと詳しく知りたい方はContact Usからお問い合わせください。"
          />

          <PageTransitionWrapper>
            <div className={classNames(styles.memberArea)}>
              <div className={classNames(styles.memberBox1)}>
                <div className={classNames(styles.memberSelectBox)}>
                  {MemberItems.map((v, i) => (
                    <Link
                      to={v.link}
                      key={i}
                      className={classNames(styles.memberImage)}
                    >
                      <img src={v.img} alt={v.name} />
                    </Link>
                  ))}
                </div>

                <img
                  src={data.img}
                  className={classNames(styles.profileImage)}
                  alt={data.name}
                />
              </div>

              <div className={classNames(styles.memberBox2)}>
                <img src={ColorTile} alt="color_tile" />

                <div
                  className={classNames(styles.border)}
                  style={{ backgroundColor: data.color }}
                />

                <div className={classNames(styles.profileBox)}>
                  {data.name && (
                    <>
                      <span>{data.role}</span>
                      <h2>{data.name}</h2>
                    </>
                  )}

                  <div>{data.desc}</div>
                </div>
              </div>
            </div>
          </PageTransitionWrapper>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
