import { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'react-router-dom';
import { getNews } from 'config/contentful/contentful';
import Title from 'components/title/Title';
import TitleImage from 'assets/title_news.png';
import { InView } from 'react-intersection-observer';
import InfiniteScroll from 'react-infinite-scroller';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';

const Limit = 4;

const NewsPage = () => {
  const nodeRef = useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(0.8);
  const [news, setNews] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [skip, setSkip] = useState(1);

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        return (
          <Link to={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </Link>
        );
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const _news = await getNews({ limit: Limit, skip: 0 });

        setNews(_news);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const loadMore = async () => {
    const _news = await getNews({ limit: Limit, skip: Limit * skip });

    if (_news.length > 0) {
      setNews((prev) => [...prev, ..._news]);
      setSkip((prev) => prev + 1);
    } else {
      setIsLoad(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>News | 株式会社HariRO.Tokyo</title>
      </Helmet>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <Title
            name="News"
            image={TitleImage}
            text="HariRO.Tokyoのインフォメーションです。実績なども含みます。"
          />
          <InfiniteScroll
            hasMore={isLoad}
            initialLoad={false}
            loadMore={loadMore}
          >
            <ul>
              {news.map((v, i) => (
                <InView rootMargin="-50px" triggerOnce key={i}>
                  {({ ref, inView }) => (
                    <CSSTransition
                      in={inView}
                      timeout={{ enter: 0, exit: 100 }}
                      nodeRef={nodeRef}
                    >
                      {(state) => (
                        <li ref={ref} style={fade[state]} key={i}>
                          <img src={v.img} alt={v.title} />
                          <div className={classNames(styles.newsBox)}>
                            <h3>
                              {dayjs(v.published_at).format('YYYY年MM月DD日')}{' '}
                              &nbsp;&nbsp;
                              {v.title}
                            </h3>
                            <div className={classNames(styles.description)}>
                              {documentToReactComponents(
                                v.description,
                                options
                              )}
                            </div>
                          </div>
                        </li>
                      )}
                    </CSSTransition>
                  )}
                </InView>
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default NewsPage;
