import classNames from 'classnames';
import styles from './style.module.scss';
import LogoFill from 'assets/logo_fill.png';

const ChatBalloon = (props) => {
  return (
    <div className={classNames(styles.chatBallon)}>
      <img src={LogoFill} alt="logo" />

      <div className={classNames(styles.arrowBox)}>
        <span>{props.text}</span>
      </div>
    </div>
  );
};

export default ChatBalloon;
