import { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createFadingAnime } from 'styles/transition';

const PageTransitionWrapper = (props) => {
  const nodeRef = useRef(null); // StrictModeエラーの対処用
  const fade = createFadingAnime(0.8);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <CSSTransition
      in={isMounted}
      timeout={{ enter: 0, exit: 5000 }}
      nodeRef={nodeRef}
    >
      {(state) => <div style={fade[state]}>{props.children}</div>}
    </CSSTransition>
  );
};

export default PageTransitionWrapper;
