import classNames from 'classnames';
import styles from './style.module.scss';
import Header from 'components/header/Header';
import { Outlet, useLocation } from 'react-router-dom';

const DefaultLayout = () => {
  const location = useLocation();
  return (
    <main className={classNames(styles.layoutContainer)}>
      <Header />
      <div className={classNames(styles.container)}>
        <Outlet />
      </div>

      {location.pathname !== '/' && (
        <div className={classNames(styles.copyright)}>
          © HariRO.Tokyo Co. Ltd. All Rights Reserved.
        </div>
      )}
    </main>
  );
};

export default DefaultLayout;
