import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import Items from 'config/header';
import { NavLink, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { createMenuAnime, createFadingDownAnime } from 'styles/transition';

const Header = () => {
  const route = useLocation();
  const nodeRef = useRef(null); // StrictModeエラーの対処用
  const countRef = useRef(null);

  const fade = createMenuAnime(1);
  const [isShowMenu, setShowMenu] = useState(false);
  const fadeDown = createFadingDownAnime(0.6);
  const [isMounted, setIsMounted] = useState(
    route.pathname === '/' ? false : true
  );

  useEffect(() => {
    if (route.pathname === '/') {
      if (countRef.current !== null) return;
      countRef.current = setInterval(() => {
        setIsMounted(true);
      }, 2000);
    }
  }, []);

  useLayoutEffect(() => {
    const checkWindowWidth = () => {
      if (window.innerWidth > 640) {
        setShowMenu(false);
      }
    };

    window.addEventListener('resize', checkWindowWidth);
    checkWindowWidth();

    return () => window.removeEventListener('resize', checkWindowWidth);
  }, []);

  return (
    <>
      <CSSTransition in={isMounted} timeout={200} nodeRef={nodeRef}>
        {(state) => (
          <nav style={fadeDown[state]} className={classNames(styles.webMenu)}>
            <div className={classNames(styles.container)}>
              <div className={classNames(styles.content)}>
                {Items.map((v, i) => (
                  <NavLink
                    to={v.link}
                    key={i}
                    className={classNames(styles.items)}
                  >
                    <div className={classNames(styles.circleBox)}>
                      <div className={classNames(styles.circle)} />
                    </div>

                    {v.label}
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        )}
      </CSSTransition>

      <nav
        className={classNames(
          styles.mobileMenu,
          isShowMenu && styles.showMenuStyle,
          route.pathname === '/' && styles.hideBlur
        )}
      >
        <div
          className={classNames(styles.hamburgerButton)}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          <span
            className={classNames(
              styles.line,
              route.pathname !== '/' && styles.red,
              styles.line01,
              isShowMenu && styles.buttonLine01
            )}
          />
          <span
            className={classNames(
              styles.line,
              route.pathname !== '/' && styles.red,
              styles.line02,
              isShowMenu && styles.buttonLine02
            )}
          />
          <span
            className={classNames(
              styles.line,
              route.pathname !== '/' && styles.red,
              styles.line03,
              isShowMenu && styles.buttonLine03
            )}
          />
        </div>
      </nav>

      <CSSTransition
        in={isShowMenu}
        timeout={5000}
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(state) => (
          <div className={classNames(styles.overlayMenu)} style={fade[state]}>
            <div className={classNames(styles.content)}>
              {Items.map((v, i) => (
                <NavLink
                  to={v.link}
                  key={i}
                  className={classNames(styles.items)}
                  onClick={() => setShowMenu(false)}
                >
                  {v.label}
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  );
};

export default Header;
